import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AllModules } from "../../variables";
import { BotSwitcher } from "./modules/BotSwitcher";
import history from "../../history";
import { updatePage } from "../../actions";
import moment from "moment";

import { faHSquare, faArrowAltCircleLeft, faTimes, faHome, faCog, faLink, faServer, faDollarSign, faQuestionCircle, faExclamationCircle, faShoppingCart, faGift, faHandshake, faDatabase, faWrench, faCodeBranch } from "@fortawesome/free-solid-svg-icons";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import CustomModuleSideMenuItem from "./elements/CustomModuleSideMenuItem";

export class MobileMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hovering: "",
			openBotSwitcher: false
		};
	}

	customModuleClick = (id, name) => {
		history.push(`/dashboard/module/${id}/${name}`);
		this.props.update();
	};
	handleMouseHover = (e, category) => {
		// this.setState({hovering:category})
		// this.setState(this.toggleHoverState);
	};
	changePage = (page) => {
		//    this.props.updatePage(page)
		history.push("/dashboard/" + page);
	};

	checkCustomModuleEnabled = (id) => {
		var enabled = false;
		if (this.props.moduleSettings[id] != undefined && this.props.moduleSettings[id].enabled == true) {
			enabled = true;
		}
		return enabled;
	};

	renderErrorCount = () => {
		if (this.props.user.logged == undefined || this.props.api_errors.length == 0) {
			return null;
		} else {
			var count = 0;
			this.props.api_errors.forEach((error) => {
				if ((this.props.logsChecked == null || moment(this.props.logsChecked).isBefore(error.timeStamp)) && moment(this.props.user.logged).isBefore(moment(error.timeStamp))) {
					count += 1;
				}
			});
			if (count == 0) {
				return null;
			} else {
				return (
					<div className="error-log-count hithere">
						<div className="error-log-count-inner">{count}</div>
					</div>
				);
			}
		}
	};

	checkEnabled = (module) => {
		if (module == "Moderation") {
			var check = false;
			Object.keys(this.props.bot.commands.moderation).forEach((key) => {
				if (this.props.bot.commands.moderation[key].enabled === true && key != "afk" && key != "unban") {
					check = true;
					// console.log(key, "KEY");
				}
			});
			return check;
		} else if (module == "Announcements" && (this.props.bot.commands.announcements.join.length > 0 || this.props.bot.commands.announcements.ban.length > 0 || this.props.bot.commands.announcements.leave.length > 0)) {
			return true;
		} else if (module == "Automod" && this.props.bot.commands.automod.automod.enabled == true) {
			return true;
		} else if (module == "Autoresponder" && this.props.bot.commands.responder.enabled == true) {
			return true;
		} else if (module == "Auto-Role" && this.props.bot.commands.autorole.enabled == true) {
			return true;
		} else if (module == "Timed Messages" && this.props.bot.commands.messenger.enabled == true) {
			return true;
		} else if (module == "Statistics Channels" && this.props.bot.commands.statsChannels.enabled == true) {
			return true;
		} else if (module == "Status" && this.props.premium == true) {
			return true;
		} else if (module == "Reaction Roles" && this.props.bot.commands.reaction_roles.enabled == true) {
			return true;
		} else if (module == "YouTube" && this.props.bot.commands.youtube.enabled == true) {
			return true;
		} else if (module == "Twitter" && this.props.bot.commands.twitter.enabled == true) {
			return true;
		} else if (module == "Reddit" && this.props.bot.commands.reddit.enabled == true) {
			return true;
		} else if (module == "Twitch" && this.props.bot.commands.twitch.length > 0) {
			return true;
		} else if (module == "Meme Creator" && this.props.bot.commands.meme_creator.enabled == true) {
			return true;
		} else if (module == "Levels" && this.props.bot.commands.levels.enabled == true) {
			return true;
		} else if (module == "Economy" && this.props.bot.commands.economy.enabled == true) {
			return true;
		} else if (module == "Music" && this.props.bot.commands.music.enabled == true) {
			return true;
		} else if (module == "Crypto" && this.props.bot.commands.crypto.enabled == true) {
			return true;
		} else if (module == "NFTs" && this.props.bot.commands.opensea.enabled == true) {
			return true;
		} else if (module == "Embed Builder") {
			return true;
		} else if (module == "Giveaways" && this.props.bot.commands.giveaways.enabled == true) {
			return true;
		} else if (module == "Trivia" && this.props.bot.commands.fun.trivia.enabled == true) {
			return true;
		} else if (module == "Search") {
			var check = false;
			Object.keys(this.props.bot.commands.search).forEach((key) => {
				if (this.props.bot.commands.search[key].enabled == true) {
					check = true;
				}
			});
			return check;
		} else if (module == "Logging") {
			var check = false;
			Object.keys(this.props.bot.commands.logging).forEach((key) => {
				if (this.props.bot.commands.logging[key].enabled == true) {
					check = true;
				}
			});
			return check;
		} else if (module == "Botlist" && this.props.bot.commands.advertise.enabled == true) {
			return true;
		} else if (module == "Weather" && this.props.bot.commands.utils.weather.enabled == true) {
			return true;
		} else if (module == "Translator" && this.props.bot.commands.utils.translate.enabled == true) {
			return true;
		} else if (module == "Co Create" && this.props.bot.commands.cocreate.enabled == true) {
			return true;
		} else if (module == "Fortnite" && this.props.bot.commands.games.fortnite.enabled == true) {
			return true;
		} else if (module == "Apex Legends" && this.props.bot.commands.games.apex.enabled == true) {
			return true;
		} else if (module == "Custom Commands" && this.props.bot.commands.slash.commands.length > 0) {
			return true;
		} else if (module == "Usage" && this.props.bot.commands.settings.embeds.enabled == true) {
			return true;
		} else if (module == "Tickets" && this.props.bot.commands.tickets.enabled == true) {
			return true;
		}
	};

	checkDepreciation(module) {
		var depreciated = true;
		if (module.name == "Reaction Roles") {
			if (this.props.bot?.commands?.reaction_roles?.enabled == true) {
				depreciated = false;
			}
		}

		return depreciated;
	}

	renderModules = () => {
		var modules = [];
		var customModules = [...this.props.modules];

		var featured = [];
		var favourited = [];
		var newModules = [];

		var favModules = this.props.user.favModules;
		var ungrouped = [];
		AllModules.forEach((moduleCategory) => {
			var module_items = [];

			moduleCategory.modules.forEach((module) => {
				if (module.name != "Text Commands" && (module.beta != true || this.props.beta == true)) {
					if (module.depreciated == true && this.checkDepreciation(module)) {
						return;
					}

					var moduleItem = (
						<li
							className={`${module.premium == true && this.props.premium == false ? "side-menu-premium" : null}`}
							onClick={() => {
								this.changePage(module.name.toLowerCase().split(" ").join(""), module.premium);
								this.props.mobileAction("close");
							}}
						>
							<span
								onMouseEnter={(e) => {
									this.handleMouseHover(e, module.name.toLowerCase().split(" ").join(""));
								}}
								onMouseLeave={(e) => {
									this.setState({ hovering: "" });
								}}
								className={`dash-sidebar-item ${window.location.pathname == `/dashboard/${module.name.toLowerCase().split(" ").join("")}` ? "active" : ""}`}
							>
								{/* <div className={`${this.checkEnabled(module.name) == true ? "dash-sidebar-item-icon-enabled" : "dash-sidebar-item-icon-disabled"}`} > */}
								<img src={process.env.PUBLIC_URL + "/images/icons/" + module.icon} className="w-[15px] h-[15px] mr-[5px] rounded-full"></img> {module.name}
							</span>
						</li>
					);

					if (module.featured) {
						featured.push(moduleItem);
					} else if (favModules.includes(module.name)) {
						favourited.push(moduleItem);
					} else if (module.new) {
						newModules.push(moduleItem);
					} else {
						module_items.push(moduleItem);
					}
				}
			});

			var index = customModules.length;

			while (index--) {
				var customModule = customModules[index];

				if (customModule.hidden) {
					customModules.splice(index, 1);
					continue;
				}
				// customModules.forEach((customModule, index) => {

				var moduleItem = (
					<CustomModuleSideMenuItem
						changePage={(id, name) => {
							this.customModuleClick(id, name);
							this.props.mobileAction("close");
						}}
						active={history.location.pathname == `/dashboard/module/${customModule.id}/${customModule.name.toLowerCase().split(" ").join("")}`}
						mobile={true}
						id={customModule.id}
						name={customModule.name}
						img={customModule.img}
						premiumRequired={customModule.premium}
					/>
				);

				if (customModule.featured) {
					featured.push(moduleItem);
					customModules.splice(index, 1);
				} else if (favModules.includes(customModule.id)) {
					favourited.push(moduleItem);
					customModules.splice(index, 1);
				} else if (customModule.new) {
					newModules.push(moduleItem);
					customModules.splice(index, 1);
				} else if (customModule.category == moduleCategory.name) {
					// Put at start of array;
					module_items.unshift(moduleItem);

					customModules.splice(index, 1);
				} else {
					// index++;
					// customModules.splice(index, 1);
				}
			}

			if (module_items.length > 0) {
				modules.push(
					<div>
						<label className="mobile-nav-header">{moduleCategory.name}</label>
						{module_items}
					</div>
				);
			}
		});
		var groupedModules = customModules.reduce((r, a) => {
			r[a.category] = [...(r[a.category] || []), a];
			return r;
		}, {});

		Object.keys(groupedModules).forEach((key, index) => {
			var module_items = [];
			groupedModules[key].forEach((customModule, index) => {
				module_items.unshift(
					<CustomModuleSideMenuItem
						changePage={(id, name) => {
							this.customModuleClick(id, name);
							this.props.mobileAction("close");
						}}
						active={history.location.pathname == `/dashboard/module/${customModule.id}/${customModule.name.toLowerCase().split(" ").join("")}`}
						mobile={true}
						id={customModule.id}
						name={customModule.name}
						img={customModule.img}
					/>
				);
			});

			modules.push(
				<div>
					<label className="mobile-nav-header">{key}</label>
					{module_items}
				</div>
			);
		});

		var featuredandfavourited = [...featured, ...favourited, ...newModules];
		if (featured.length > 0) {
			modules.unshift(
				<div>
					<label className="mobile-nav-header">Featured & Favourited</label>
					{featuredandfavourited}
				</div>
			);
		}

		return modules;
	};

	render() {
		if (this.props.mobileNav) {
			return (
				<div className="mobile-nav op animate__animated animate__slideInLeft" id="mobile-nav">
					<ul className="mobile-nav-list">
						<div className="mobile-header-logo">
							<div className="mobile-nav-close" onClick={() => this.props.mobileAction("close")}>
								<FontAwesomeIcon icon={faTimes} fixedWidth />
							</div>
							<img src={process.env.PUBLIC_URL + "/images/logo-red.png"}></img>
							<span>BotGhost</span>
						</div>
						<div className="mobile-menu-body m-mt-15">
							{/* <label>DASHBOARD</label> */}
							<li
								onClick={(e) => {
									this.changePage("modules");
									this.props.mobileAction("close");
								}}
							>
								<span
									onMouseEnter={(e) => {
										this.handleMouseHover(e, "modules");
									}}
									onMouseLeave={(e) => {
										this.setState({ hovering: "" });
									}}
									className={`dash-sidebar-item ${window.location.pathname == "/dashboard/modules" || window.location.pathname == "/dashboard" ? "active" : ""}`}
								>
									<FontAwesomeIcon fixedWidth icon={faHome} /> Modules
								</span>
							</li>

							<li
								href="https://botghost.com/market"
								onClick={() => {
									// Open in new tab
									var win = window.open("https://botghost.com/market", "_blank");
								}}
								target="_blank"
							>
								<span
									onMouseEnter={(e) => {
										this.handleMouseHover(e, "freepremium");
									}}
									onMouseLeave={(e) => {
										this.setState({ hovering: "" });
									}}
									className={`dash-sidebar-item ${window.location.pathname == "/dashboard/market" ? "active" : ""}`}
								>
									<FontAwesomeIcon icon={faShoppingCart} fixedWidth /> Market
								</span>
							</li>

							<a href="https://botpanel.gg/" target="_blank">
								<li
									id="premium-side-menu"
									onClick={(e) => {

									}}
								>
									<span
										onMouseEnter={(e) => {
											this.handleMouseHover(e, "premium");
										}}
										onMouseLeave={(e) => {
											this.setState({ hovering: "" });
										}}
										className={`dash-sidebar-item ${window.location.pathname == "/dashboard/premium" ? "active" : ""}`}
									>
										<img style={{ height: "20px", marginRight: "10px" }} src={"https://botpanel.gg/img/logo.png"}></img> <span className="flex items-center">BotPanel <span className="text-xs bg-blue text-white px-2 rounded-md ml-2">New!</span></span>
									</span>
								</li>
							</a>

							<label className="mobile-nav-header">Settings</label>
							{/* Settings */}
							<li
								id="settings-side-menu"
								onClick={(e) => {
									this.changePage("settings");
									this.props.mobileAction("close");
								}}
							>
								<span
									onMouseEnter={(e) => {
										this.handleMouseHover(e, "settings");
									}}
									onMouseLeave={(e) => {
										this.setState({ hovering: "" });
									}}
									className={`dash-sidebar-item ${window.location.pathname == "/dashboard/settings" ? "active" : ""}`}
								>
									<FontAwesomeIcon icon={faCog} fixedWidth /> Settings
								</span>
							</li>

							{/* Invite */}

							<a className="mobile-nav-padding" target="_blank" href={`https://discord.com/oauth2/authorize?client_id=${this.props.bot.id}&scope=applications.commands%20bot&permissions=8`}>
								<li>
									<span
										id="invite-side-menu"
										onMouseEnter={(e) => {
											this.handleMouseHover(e, "invite");
										}}
										onMouseLeave={(e) => {
											this.setState({ hovering: "" });
										}}
										className={`dash-sidebar-item ${this.props.page == "invite" ? "active" : ""}`}
									>
										<FontAwesomeIcon icon={faLink} fixedWidth /> Invite
									</span>
								</li>
							</a>

							{/* Data storage */}

							<li
								onClick={() => {
									this.changePage("datastorage");
								}}
							>
								<span
									onMouseEnter={(e) => {
										this.handleMouseHover(e, "servers");
									}}
									onMouseLeave={(e) => {
										this.setState({ hovering: "" });
									}}
									className={`dash-sidebar-item ${window.location.pathname == "/dashboard/datastorage" ? "active" : ""}`}
								>
									<FontAwesomeIcon icon={faDatabase} fixedWidth /> Data Storage
								</span>
							</li>

							{/* Servers */}

							<li
								onClick={() => {
									this.changePage("servers");
									this.props.mobileAction("close");
								}}
							>
								<span
									onMouseEnter={(e) => {
										this.handleMouseHover(e, "servers");
									}}
									onMouseLeave={(e) => {
										this.setState({ hovering: "" });
									}}
									className={`dash-sidebar-item ${window.location.pathname == "/dashboard/servers" ? "active" : ""}`}
								>
									<FontAwesomeIcon icon={faServer} fixedWidth /> Servers
								</span>
							</li>

							{/* Status */}

							<li
								onClick={() => {
									this.changePage("module/42056fd5-1c1d-4f89-8db7-794fe732424c/status");
								}}
							>
								<span
									onMouseEnter={(e) => {
										this.handleMouseHover(e, "freepremium");
									}}
									onMouseLeave={(e) => {
										this.setState({ hovering: "" });
									}}
									className={`dash-sidebar-item ${window.location.pathname == "/dashboard/module/42056fd5-1c1d-4f89-8db7-794fe732424c/status" ? "active" : ""}`}
								>
									<FontAwesomeIcon icon={faWrench} fixedWidth /> Status
								</span>
							</li>

							{/* Collab */}
							{this.props.collab != true ? (
								<li
									id="settings-side-menu"
									onClick={(e) => {
										this.changePage("collab");
										this.props.mobileAction("close");
									}}
								>
									<span
										onMouseEnter={(e) => {
											this.handleMouseHover(e, "collab");
										}}
										onMouseLeave={(e) => {
											this.setState({ hovering: "" });
										}}
										className={`dash-sidebar-item ${window.location.pathname == "/dashboard/collab" ? "active" : ""}`}
									>
										<FontAwesomeIcon icon={faHandshake} fixedWidth /> Collab
									</span>
								</li>
							) : null}

							{/* Error Logs */}

							<li
								onClick={(e) => {
									this.changePage("errors");
									this.props.mobileAction("close");
								}}
							>
								<span
									onMouseEnter={(e) => {
										this.handleMouseHover(e, "errors");
									}}
									onMouseLeave={(e) => {
										this.setState({ hovering: "" });
									}}
									className={`dash-sidebar-item ${window.location.pathname == "/dashboard/errors" ? "active" : ""}`}
								>
									<FontAwesomeIcon icon={faExclamationCircle} fixedWidth size="1x" /> Error Logs {this.renderErrorCount()}
								</span>
							</li>

							{/* Active dev badge */}

							<li
								onClick={() => {
									this.changePage("module/be383fbe-8339-433b-a51c-94db234fde38/activedeveloperbadge");
								}}
							>
								<span
									onMouseEnter={(e) => {
										this.handleMouseHover(e, "freepremium");
									}}
									onMouseLeave={(e) => {
										this.setState({ hovering: "" });
									}}
									className={`dash-sidebar-item ${window.location.pathname == "/dashboard/module/be383fbe-8339-433b-a51c-94db234fde38/activedeveloperbadge" ? "active" : ""}`}
								>
									<FontAwesomeIcon icon={faCodeBranch} fixedWidth /> Active Developer Badge
								</span>
							</li>

							<label className="mobile-nav-header">Premium</label>
							<li
								id="premium-side-menu"
								onClick={(e) => {
									this.changePage("premium");
									this.props.mobileAction("close");
								}}
							>
								<span
									onMouseEnter={(e) => {
										this.handleMouseHover(e, "premium");
									}}
									onMouseLeave={(e) => {
										this.setState({ hovering: "" });
									}}
									className={`dash-sidebar-item ${window.location.pathname == "/dashboard/premium" ? "active" : ""}`}
								>
									<img style={{ height: "20px", marginRight: "10px" }} src={process.env.PUBLIC_URL + "/images/crown.svg"}></img> Premium
								</span>
							</li>

							{/* <li
								onClick={() => {
									this.changePage("freepremium");
									this.props.mobileAction("close");
								}}
							>
								<span
									onMouseEnter={(e) => {
										this.handleMouseHover(e, "freepremium");
									}}
									onMouseLeave={(e) => {
										this.setState({ hovering: "" });
									}}
									className={`dash-sidebar-item ${window.location.pathname == "/dashboard/freepremium" ? "active" : ""}`}
								>
									<FontAwesomeIcon icon={faDollarSign} fixedWidth /> Free Premium
								</span>
							</li> */}

							<li
								onClick={() => {
									this.changePage("gifting");
									this.props.mobileAction("close");
								}}
							>
								<span
									onMouseEnter={(e) => {
										this.handleMouseHover(e, "gifting");
									}}
									onMouseLeave={(e) => {
										this.setState({ hovering: "" });
									}}
									className={`dash-sidebar-item ${window.location.pathname == "/dashboard/gifting" ? "active" : ""}`}
								>
									<FontAwesomeIcon icon={faGift} fixedWidth /> Gifting
								</span>
							</li>

							<label className="mobile-nav-header">Help</label>

							<a className="mobile-nav-padding" target="_blank" href={`https://discord.gg/botghost`}>
								<li>
									<span
										onMouseEnter={(e) => {
											this.handleMouseHover(e, "invite");
										}}
										onMouseLeave={(e) => {
											this.setState({ hovering: "" });
										}}
										className={`dash-sidebar-item ${this.props.page == "invite" ? "active" : ""}`}
									>
										<FontAwesomeIcon icon={faDiscord} fixedWidth /> Support Server
									</span>
								</li>
							</a>

							{this.renderModules()}
						</div>
					</ul>
				</div>
			);
		} else {
			return <></>;
		}
	}
}

const mapStateToProps = (state) => ({
	page: state.page,
	bot: state.data.bot,
	bots: state.data.bots,
	premium: state.data.premium,
	quick_start: state.quick_start,
	user: state.data.user,
	beta: state.data.beta,
	api_errors: state.data.api_errors,
	logsChecked: state.data.logsChecked,
	moduleSettings: state.data.bot.commands.moduleSettings,
	modules: state.data.modules,
	collab: state.data.collab
});

const mapDispatchToProps = {
	updatePage
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData } from '../../../../actions';

import TextArea from '../../elements/TextArea';
import RequestBuilder from '../../elements/RequestBuilder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faClipboard } from '@fortawesome/free-solid-svg-icons';
import update from "immutability-helper";
import VideoLink from '../../elements/VideoLink';
import { toast } from 'react-toastify';
import { copyToast } from '../toast';

export class HTTPRequestAction extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openBuilder: false,
            openTester: false
        };
    }

    componentDidMount() {
        // console.log("Updating data MOUNTED", this.props.id);
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "api_action",
                        ephemeral: false,
                        name: "",
                        request_options: {
                            method: "GET",
                            url: "",
                            params: [{ key: "", value: "" }],
                            headers: [{ key: "", value: "" }],
                            body: [{ key: "", value: "" }],
                            options: {
                                excludeEmptyFields: true,
                                replaceParams: true,
                                replaceURL: true,
                                replaceHeaders: true,
                                replaceBody: true
                            }
                        },
                        validated: false,
                    },
                    id: this.props.id
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.data == undefined) {
            // console.log("Updating data", prevProps, this.props, this.props.id);

            this.props.updateElementData(
                {
                    data: {
                        type: "api_action",
                        ephemeral: false,
                        name: "",
                        request_options: {
                            method: "GET",
                            url: "",
                            params: [{ key: "", value: "" }],
                            headers: [{ key: "", value: "" }],
                            body: [{ key: "", value: "" }],
                            options: {
                                excludeEmptyFields: true,
                                replaceParams: true,
                                replaceURL: true,
                                replaceHeaders: true,
                                replaceBody: true,
                            }
                        },
                        validated: false,
                    },
                    id: this.props.id
                }
            );
        }
        else {
            // VALIDATE

            if (this.props.data.name != "" && this.props.data.request_options.url != "" && this.validateExistingNames()) {
                if (this.props.data.validated == false) {
                    this.updateData("validated", true);
                }
            } else if (this.props.data.validated == true) {
                this.updateData("validated", false);
            }
        }
    }

    updateData = (key, value) => {

        // console.log("VALUE", value);
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };

    nameChange = (value) => {



        value = value.toLowerCase();
        value = value.substring(0, 32);
        value = value.replace(" ", "-");
        // value = value.replace(/[^\w]+/gi,"")
        // value = value.replace(/[^[A-zÀ-Ÿ\d-]+/gi,"")
        const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
        value = value.replace(regexForNonAlphaNum, "");
        this.updateData("name", value);
    };

    validateExistingNames = () => {
        var pass = true;
        var elements = this.props.elements;
        elements.forEach(element => {
            if (element.id != this.props.id && element.type == 'action' && element.data.node_options.title == 'Send an API Request' && 'data' in element.data) {
                if (element.data.data.name != "" && element.data.data.name == this.props.data.name) {
                    pass = false;
                }
            } else if (element.id != this.props.id && element.type == 'action' && element.data.node_options.title == 'Send a Form' && 'data' in element.data) {
                if (element.data.data.modal_id != "" && element.data.data.modal_id == this.props.data.name) {
                    pass = false;
                }
            }
        });


        return pass;
    };


    render() {
        if (this.props.data != undefined) {
            return (

                <>
                    {this.state.openBuilder ? <RequestBuilder name={this.props.data.name} request_options={this.props.data.request_options} updateData={(data) => {

                        this.updateData("request_options", data);
                    }} closeModal={() => {
                        this.setState({ openBuilder: false });
                    }} /> : null}

                    <div>


                        <div className="mb-15">
                            <h4>Send an API Request<DocsLink location="https://docs.botghost.com/custom-commands/api-requests" /><VideoLink location="https://youtu.be/uQpeBERJvVI?si=OEd8rSepm8f_Aqcn" /></h4>
                            <p>Send a HTTP Request to an external API. You can use the results in all other actions and conditions.</p>
                        </div>


                        <div className="mb-15">
                            <h4 style={{ marginBottom: "0px" }}>Name<DocsLink location="https://docs.botghost.com/custom-commands/api-requests#setting-a-request-name" /></h4>

                            <div className="section-content-header mb-15">A name for this request. This will be used as a variable to access the data returned by the request.</div>
                            <div className="long-input mb-15" style={{ width: "100%" }}>
                                <label>Name</label>
                                <input value={this.props.data.name} className={`${this.props.data.name == "" ? "command-required" : null}`} placeholder="request" required type="text" onChange={(e) => {
                                    this.nameChange(e.target.value);
                                    // this.updateData("name", e.target.value);
                                }}></input>
                            </div>
                            {!this.validateExistingNames() ? <p style={{ marginTop: "0px", color: "#f45142" }}>An API Request or Send a Form action with this name already exists. Please rename this action.</p>
                                : null}
                        </div>

                        <h4 style={{ marginBottom: "0px" }}>Request Builder {this.props.data.request_options.url == "" ? <FontAwesomeIcon icon={faExclamationCircle} color="#f45142" /> : <FontAwesomeIcon icon={faCheckCircle} color="green" />}</h4>
                        <div className="section-content-header mb-15">Click the button below to open the HTTP Request builder.</div>
                        <div className='mb-15'>
                            <button disabled={this.props.data.name == "" ? true : false} className="btn btn-primary" onClick={() => {
                                this.setState({ openBuilder: true });
                            }}>Request Builder</button>
                        </div>

                        {this.props.data.name != "" ? <>
                            <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>



                            <h4>Using Responses <DocsLink location="https://docs.botghost.com/custom-commands/api-requests#api-action-variables" /></h4>
                            <p className="mb-15" style={{
                                color: "#fff",
                                opacity: "0.7",
                                fontWeight: 500
                            }}>You can use the <span style={{ color: "#f45142", opacity: "1 !important" }}>{`{${this.props.data.name}}`}</span> variable with dot notation to access data returned by the API request. Test the value of the variable using the Text Request feature in the Request Builder above. </p>

                            <p style={{
                                color: "#fff",
                                opacity: "0.7",
                                fontWeight: 500
                            }}><span style={{ color: "#f45142" }}>{`{${this.props.data.name}.response}`}</span> <FontAwesomeIcon icon={faClipboard} fixedWidth className='text-sm hover:text-red hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`{${this.props.data.name}.response}`); copyToast("Copied Variable"); }} /> - Returns the response of the request.</p>

                            <p style={{
                                color: "#fff",
                                opacity: "0.7",
                                fontWeight: 500
                            }}><span style={{ color: "#f45142" }}>{`{${this.props.data.name}.status}`}</span> <FontAwesomeIcon icon={faClipboard} fixedWidth className='text-sm hover:text-red hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`{${this.props.data.name}.status}`); copyToast("Copied Variable"); }} /> - Returns the status code of the request.</p>

                            <p style={{
                                color: "#fff",
                                opacity: "0.7",
                                fontWeight: 500
                            }}><span style={{ color: "#f45142" }}>{`{${this.props.data.name}.statusText}`}</span> <FontAwesomeIcon icon={faClipboard} fixedWidth className='text-sm hover:text-red hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`{${this.props.data.name}.statusText}`); copyToast("Copied Variable"); }} /> - Returns the statusText of the request.</p>


                        </> : null}

                    </div>
                </>

            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed
});
const mapDispatchToProps = {
    updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(HTTPRequestAction);

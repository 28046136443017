import React, { useState } from 'react';
import { connect } from 'react-redux';
import server from "../../api/server";

export const FreeTrialBanner = ({ bot_id }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleClaimPremium = async () => {
        setErrorMessage(''); // Clear any existing error
        setIsLoading(true);

        try {
            const response = await server.post('/dashboard/server_upgrade', {
                bot_id: bot_id
            });

            if (!response.data.success) {
                switch (response.data.reason) {
                    case 'premium':
                        setErrorMessage('This bot already has premium activated');
                        break;
                    case 'claimed':
                        setErrorMessage('You have already claimed your free trial');
                        break;
                    case 'not_joined':
                        setErrorMessage('You must first join the server to claim premium');
                        break;
                    case 'auth':
                        setErrorMessage('You must be logged in to claim premium');
                        break;
                    default:
                        setErrorMessage('An error occurred while claiming premium');
                }
            } else {
                setErrorMessage('');
                // Success handling here
                // Reload page
                window.location.reload();
            }
        } catch (error) {
            setErrorMessage('An error occurred while claiming premium');
            console.error('Error claiming premium:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="relative overflow-hidden bg-[#0f1a2a] p-6 sm:p-12 rounded-2xl my-4 text-white">
            {/* Dark gradient overlay */}
            <div className="absolute inset-0 bg-gradient-to-r from-[#0f1a2a] via-[#152238] to-[#0f1a2a]" />

            {/* Enhanced Blue/Purple Premium Accent Glows */}
            <div className="absolute -bottom-32 right-0 w-2/3 h-[150%] bg-gradient-to-tl from-[#4f46e5]/30 via-[#7c3aed]/20 to-transparent blur-3xl" />
            <div className="absolute top-0 right-0 w-1/2 h-full bg-gradient-to-bl from-[#7c3aed]/25 via-[#4f46e5]/15 to-transparent blur-2xl" />

            {/* Additional subtle premium accent */}
            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-[#4f46e5]/[0.05] to-[#7c3aed]/[0.08]" />

            <div className="flex flex-col lg:flex-row items-center justify-between relative z-10 gap-8 lg:gap-0">
                {/* Content */}
                <div className="relative z-10 w-full lg:max-w-xl">
                    <h1 className="text-3xl sm:text-4xl font-bold mb-3">
                        Try Premium Free:{' '}
                        <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#4f46e5] to-[#7c3aed]">
                            24 Hours Access
                        </span>
                    </h1>

                    <p className="text-base sm:text-lg leading-relaxed text-gray-300 mb-5 lg:pr-12">
                        Join our server and unlock <span className="font-bold text-[#4f46e5]">24 hours</span> of premium features completely free! Experience the full power of our bot with unlimited access to all premium commands and features.
                    </p>

                    <div className="flex flex-col gap-2">
                        <div className="flex gap-4">
                            <button
                                onClick={handleClaimPremium}
                                disabled={isLoading}
                                className={`relative w-full sm:w-auto px-8 py-3 rounded-lg font-semibold overflow-hidden
                                    transition-all duration-200 hover:-translate-y-0.5 active:translate-y-0 
                                    group cursor-pointer bg-[#6366F1] disabled:opacity-50 disabled:cursor-not-allowed
                                    ${isLoading ? 'cursor-wait' : ''}`}
                            >
                                {/* Gradient background */}
                                <div className="absolute inset-0 bg-gradient-to-r from-[#4f46e5] via-[#5a51e6] to-[#7c3aed] 
                                    group-hover:opacity-90 transition-opacity" />

                                {/* Shine effect */}
                                <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/10 to-transparent 
                                    translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-1000" />

                                {/* Button shadow */}
                                <div className="absolute inset-0 shadow-xl shadow-[#4f46e5]/20" />

                                {/* Button text */}
                                <span className="relative z-10 flex items-center justify-center gap-2">
                                    {isLoading ? (
                                        <>
                                            <svg className="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            Processing...
                                        </>
                                    ) : (
                                        'Claim Premium'
                                    )}
                                </span>
                            </button>

                            <a
                                href="https://discord.gg/botghost"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="relative w-full sm:w-auto px-8 py-3 rounded-lg font-semibold overflow-hidden
                                    transition-all duration-200 hover:-translate-y-0.5 active:translate-y-0 
                                    group cursor-pointer bg-[#6B7280] text-white no-underline"
                            >
                                {/* Gradient background */}
                                <div className="absolute inset-0 bg-gradient-to-r from-[#4B5563] via-[#6B7280] to-[#4B5563] 
                                    group-hover:opacity-90 transition-opacity" />

                                {/* Shine effect */}
                                <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/10 to-transparent 
                                    translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-1000" />

                                {/* Button shadow */}
                                <div className="absolute inset-0 shadow-xl shadow-gray-500/20" />

                                {/* Button text */}
                                <span className="relative z-10 flex items-center gap-2 text-white">
                                    Join Server
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M20.317 4.37a19.791 19.791 0 0 0-4.885-1.515a.074.074 0 0 0-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 0 0-5.487 0a12.64 12.64 0 0 0-.617-1.25a.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 4.37a.07.07 0 0 0-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 0 0 .031.057a19.9 19.9 0 0 0 5.993 3.03a.078.078 0 0 0 .084-.028a14.09 14.09 0 0 0 1.226-1.994a.076.076 0 0 0-.041-.106a13.107 13.107 0 0 1-1.872-.892a.077.077 0 0 1-.008-.128a10.2 10.2 0 0 0 .372-.292a.074.074 0 0 1 .077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 0 1 .078.01c.12.098.246.198.373.292a.077.077 0 0 1-.006.127a12.299 12.299 0 0 1-1.873.892a.077.077 0 0 0-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.028a19.839 19.839 0 0 0 6.002-3.03a.077.077 0 0 0 .032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 0 0-.031-.03zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419c0-1.333.956-2.419 2.157-2.419c1.21 0 2.176 1.096 2.157 2.42c0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419c0-1.333.955-2.419 2.157-2.419c1.21 0 2.176 1.096 2.157 2.42c0 1.333-.946 2.418-2.157 2.418z" />
                                    </svg>
                                </span>
                            </a>
                        </div>

                        {errorMessage && (
                            <div className="flex items-center gap-2 bg-[#1E1E2D] border border-[#2B2B40] rounded-lg p-3 mt-2">
                                <svg className="w-5 h-5 text-white" fill="currentColor" viewBox="0 0 20 20">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                </svg>
                                <span className="text-white font-medium">
                                    {errorMessage}
                                </span>
                            </div>
                        )}
                    </div>
                </div>

                {/* Promo Image - Hidden on mobile */}
                <div className="hidden lg:block relative z-10 w-[150px] mr-16">
                    <div className="animate-bounce">
                        <img
                            src={process.env.PUBLIC_URL + "/images/logo-red.png"}
                            alt="Premium Trial"
                            className="w-full h-auto transform hover:scale-105 transition-transform duration-500"
                        />
                    </div>
                </div>
            </div>

            {/* Floating stars decoration */}
            <div className="absolute top-0 left-0 w-full h-full pointer-events-none z-20">
                <div className="absolute top-2 right-[5%] text-white/30 text-3xl animate-pulse delay-100">✦</div>
                <div className="absolute top-16 right-[15%] text-white/40 text-2xl animate-pulse delay-300">✧</div>
                <div className="absolute top-4 right-[25%] text-white/30 text-3xl animate-pulse delay-500">✦</div>
                <div className="absolute top-20 right-[35%] text-white/40 text-2xl animate-pulse delay-700">✧</div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    bot_id: state.data.bot.id // Assuming you have the bot ID in your Redux state
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FreeTrialBanner);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import BotPanelBanner from './BotPanelBanner';
import FreeTrialBanner from './FreeTrialBanner';
import PersonalSaleBanner from './PersonalSaleBanner';

export class BannerRender extends Component {



    renderBanner() {
        // If no free trial, show the free trial banner (placeholder)
        if (!this.props.premium && !this.props.user.server_claimed) {
            return (
                <div>
                    <FreeTrialBanner />
                </div>
            );
        }
        // Else if free trial claimed and they currently have a free trial premium, show them the personal sale banner
        else if (!this.props.premium && this.props.user.personal_sale) {
            return (
                <div>
                    <PersonalSaleBanner />
                </div>
            );
        }
        // Else show the normal banner
        else {
            return <BotPanelBanner />;
        }
    }
    render() {
        return (
            <>
                {this.renderBanner()}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    premium: state.data.premium,
    user: state.data.user
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BannerRender);
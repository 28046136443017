import React, { Component } from "react";
import { connect } from "react-redux";

import Select from "../elements/Select";
import Embed from "../elements/Embed";
import TextArea from "../elements/TextArea";
import RoleAdd from "../elements/RoleAdd";

const SCOPES = [
	{ label: "Global", value: "global" },
	{ label: "Server Specific", value: "server" }
];
export class CustomVariable extends Component {
	constructor(props) {
		super(props);

		this.state = {
			variable: {
				name: "",
				reference: "",
				type: "object",
				dataType: "object",
				defaultValue: [{ key: "name", value: "Tom" }],
				global: false
			},
			error: ""
		};
	}

	renderVariableTypes = () => {
		var VARIABLE_TYPES = [
			{ label: "Text", value: "text" },
			{ label: "Number", value: "number" },
			{ label: "User", value: "user" },
			{ label: "Channel", value: "channel" },
			{ label: "Collection", value: "array" },
			{ label: "Object", value: "object" }
		];

		return VARIABLE_TYPES;
	};
	nameChange = (value) => {
		value = value.toLowerCase();
		value = value.substring(0, 32);

		value = value.split(" ").join("_");
		const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_]+/gu);
		value = value.replace(regexForNonAlphaNum, "");

		return value;
	};

	componentDidMount() {
		if (this.props.variable != undefined) {
			var variable = { ...this.state.variable };
			Object.keys(this.props.variable).forEach((key) => {
				variable[key] = this.props.variable[key];
			});
			this.setState({ variable });
		}
	}

	renderObjectKeyValuePairs = () => {
		var objectArray = this.state.variable.defaultValue;
		// Object example
		// {"key":"name", value:"Tom"}

		if (objectArray == undefined) {
			return;
		}

		var objectElements = [];

		objectArray.forEach((defaultKeyValue, index) => {
			objectElements.push(
				<div>
					{/* Add Key default key value pairs */}

					{/* Tailwind 3 cols */}
					<div className="mb-15 grid grid-cols-2 lg:grid-cols-7 gap-x-1">
						<div className="long-input col-span-1 lg:col-span-2 mb-15">
							<label>Variable</label>
							<input type="text" value={`{BGVAR_${this.state.variable.reference}.${defaultKeyValue.key}}`} disabled={true}></input>
						</div>
						<div className="long-input col-span-1 lg:col-span-2 mb-15">
							<label>Key</label>
							<input
								type="text"
								value={defaultKeyValue.key}
								onChange={(e) => {
									var variable = { ...this.state.variable };
									var value = e.target.value;
									value = value.substring(0, 100);
									// value = value.replace(" ","-")
									value = value.split(" ").join("_");
									const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_\p{Emoji_Presentation}]+/gu);
									value = value.replace(regexForNonAlphaNum, "");
									// console.log(value, 'VALUE NOW ');
									variable.defaultValue[index].key = value;
									this.setState({ variable: variable });
								}}
							></input>
						</div>
						<div className="long-input col-span-2 mb-15">
							<label>Value</label>
							<input
								type="text"
								value={defaultKeyValue.value}
								onChange={(e) => {
									var variable = { ...this.state.variable };
									variable.defaultValue[index].value = e.target.value;
									this.setState({ variable: variable });
								}}
							></input>
						</div>
						<div className="col-span-2 lg:col-span-1 flex h-[50px]">
							<button
								className="btn btn-red"
								type="button"
								onClick={() => {
									// Delete at index
									var variable = { ...this.state.variable };
									variable.defaultValue.splice(index, 1);
									this.setState({ variable: variable });
								}}
							>
								Remove
							</button>
						</div>

						<hr className="slash-hr lg:hidden col-span-2" style={{ borderTop: "1px solid #adb5bd" }}></hr>

						{/* HR */}
					</div>
				</div>
			);
		});

		return objectElements;
	};
	render() {
		return (
			<form
				onSubmit={(event) => {
					event.preventDefault();
					var variable = { ...this.state.variable };
					var variables = { ...this.props.variables };
					// console.log(variable.reference, 'VARIABLE REFERENCE');
					if (variable.reference == "") {
						this.setState({ error: "Please choose a name for your variable. Symbols can not be used." });
						return;
					}

					var existingVariableIndex = variables.variables.findIndex((v) => v.reference == variable.reference);

					if (existingVariableIndex != -1) {
						if (this.props.index == "new") {
							this.setState({ error: "A variable with this name already exists. Please choose a different name." });
							return;
						} else if (existingVariableIndex != this.props.index) {
							this.setState({ error: "A variable with this name already exists. Please choose a different name." });
							return;
						}
					}
					this.props.editVariable(variable, this.props.index);
					if (this.props.index == "new") {
						this.setState({
							variable: {
								name: "",
								reference: "",
								type: "text",
								dataType: "text",
								defaultValue: "",
								global: false
							}
						});
					}
				}}
			>
				<span className="mb-15" style={{ color: "red", fontWeight: 800 }}>
					{this.state.error}
				</span>
				<div class="mb-15" id="autoresponse-trigger">
					<h3 style={{ marginBottom: "0px" }}>Name </h3>
					<div className="section-content-header">A descriptive name for this variable.</div>
				</div>

				<div className="long-input mb-15">
					<label>Name</label>
					<input
						required
						type="text"
						value={this.state.variable.name}
						maxLength="50"
						onChange={(e) => {
							var variable = { ...this.state.variable };
							var name = e.target.value;

							// const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_]+/gu);
							// allow spaces
							const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_\s]+/gu);
							name = name.replace(regexForNonAlphaNum, "");
							variable.name = name;
							variable.reference = this.nameChange(name);
							this.setState({ variable: variable });
						}}
					></input>
				</div>

				<div>
					<div class="mb-15" id="autoresponse-trigger">
						<h3 style={{ marginBottom: "0px" }}>Reference </h3>
						<div className="section-content-header">The variable tag used to reference this variable in your bot. This is the name converted to lower case. You can use this variable in custom commands.</div>
					</div>

					<div className="long-input mb-15">
						<label>Reference</label>
						<input
							required
							type="text"
							disabled
							value={"{BGVAR_" + this.state.variable.reference + "}"}
							onChange={(e) => {
								var variable = { ...this.state.variable };
								variable.name = e.target.value;
								this.setState({ variable: variable });
							}}
						></input>
					</div>
				</div>

				<hr className="slash-hr" style={{ borderTop: "1px solid #adb5bd" }}></hr>

				<div className="mb-15">
					<div class="mb-15" id="autoresponse-trigger">
						<h3 style={{ marginBottom: "0px" }}>Variable Type </h3>
						<div className="section-content-header">The type of data to store in this variable. If a User variable is selected, variable data will be assigned to each user. If a Channel variable is selected, variable data will be assigned to each channel.</div>
					</div>

					<Select
						options={this.renderVariableTypes()}
						value={this.state.variable.type}
						onChange={(value) => {
							var variable = { ...this.state.variable };
							variable.type = value;
							if (value == "object" || (value == "channel" && variable.dataType == "object") || (value == "user" && variable.dataType == "object")) {
								// Set defaultValue to an array
								variable.defaultValue = [];
							} else {
								variable.defaultValue = "";
							}
							this.setState({ variable: variable });
						}}
					/>
				</div>

				{this.state.variable.type == "user" || this.state.variable.type == "channel" ? (
					<div className="mb-15">
						<div class="mb-15" id="autoresponse-trigger">
							<h3 style={{ marginBottom: "0px" }}>{this.state.variable.type == "user" ? "User" : "Channel"} Data Type </h3>
							<div className="section-content-header">The type of data to store for each {this.state.variable.type}.</div>
						</div>

						<Select
							options={[
								{ label: "Text", value: "text" },
								{ label: "Number", value: "number" },
								{ label: "Collection", value: "array" },
								{ label: "Object", value: "object" }
							]}
							value={this.state.variable.dataType}
							onChange={(value) => {
								var variable = { ...this.state.variable };
								variable.dataType = value;
								if (value == "object") {
									variable.defaultValue = [];
								} else {
									variable.defaultValue = "";
								}
								this.setState({ variable: variable });
							}}
						/>
					</div>
				) : null}
				<hr className="slash-hr" style={{ borderTop: "1px solid #adb5bd" }}></hr>

				{this.state.variable.type == "object" || (this.state.variable.type == "user" && this.state.variable.dataType == "object") || (this.state.variable.type == "channel" && this.state.variable.dataType == "object") ? (
					<div>
						<div class="mb-15" id="autoresponse-trigger">
							<h3 style={{ marginBottom: "0px" }}>Default Object Properties </h3>
							<div className="section-content-header">The default properties of this object.</div>
						</div>
						{this.renderObjectKeyValuePairs()}

						<div>
							<button
								className="btn bg-green text-white"
								type="button"
								onClick={(e) => {
									if (this.state.variable.defaultValue.length >= 100) {
										this.setState({ error: "You can only have 100 properties for an object." });
										return;
									}
									var variable = { ...this.state.variable };
									variable.defaultValue.push({ key: "", value: "" });
									this.setState({ variable: variable });
								}}
							>
								Add Property
							</button>
						</div>
					</div>
				) : (
					<div>
						<div class="mb-15" id="autoresponse-trigger">
							<h3 style={{ marginBottom: "0px" }}>Default Value </h3>
							<div className="section-content-header">The starting value of this variable before being set.</div>
						</div>

						<div className="long-input mb-15">
							<label>Default Value</label>
							<input
								type={this.state.variable.type == "number" ? "number" : "text"}
								value={this.state.variable.defaultValue}
								onChange={(e) => {
									var variable = { ...this.state.variable };
									variable.defaultValue = e.target.value;
									this.setState({ variable: variable });
								}}
							></input>
						</div>
					</div>
				)}

				<div className="mb-15">
					{this.state.variable.type != "channel" ? (
						<>
							<hr className="slash-hr" style={{ borderTop: "1px solid #adb5bd" }}></hr>

							<div class="mb-15" id="autoresponse-trigger">
								<h3 style={{ marginBottom: "0px" }}>Scope </h3>
								<div className="section-content-header">Whether this variable should have different values based on the server it is used in.</div>
							</div>

							<Select
								options={SCOPES}
								value={this.state.variable.global == true ? "global" : "server"}
								onChange={(value) => {
									var variable = { ...this.state.variable };
									if (value == "global") {
										variable.global = true;
									} else {
										variable.global = false;
									}
									this.setState({ variable: variable });
								}}
							/>
						</>
					) : null}

					<hr class="slashcommand-hr" />
					<div style={{ display: "flex" }}>
						<div style={{ marginLeft: "auto" }} className="m-ml-0 m-w-100 m-buttons m-mt-0">
							{this.props.index != "new" ? (
								<button
									style={{ marginRight: "15px" }}
									onClick={() => {
										this.props.delete(this.props.index);
									}}
									className="btn btn-gray mt-15 mr-15"
									type="button"
								>
									Delete
								</button>
							) : null}
							<button className="btn btn-red mt-15 m-mt-0 m-w-100" type="submit">
								{this.props.index == "new" ? "Add" : "Edit"} Custom Variable
							</button>
						</div>
					</div>
				</div>
			</form>
		);
	}
}

const mapStateToProps = (state) => ({
	variables: state.data.bot.commands.variables,
	beta: state.data.beta
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomVariable);

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import history from '../../history';
export const PersonalSaleBanner = ({ personal_sale }) => {
    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    });

    useEffect(() => {
        if (!personal_sale?.created_at) return;

        // Calculate end date as 3 days after created_at
        const endDate = moment(personal_sale.created_at).add(3, 'days');

        const timer = setInterval(() => {
            const now = moment();
            const duration = moment.duration(endDate.diff(now));

            // If countdown is finished
            if (duration.asMilliseconds() <= 0) {
                setTimeLeft({
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0
                });
                clearInterval(timer);
                return;
            }

            setTimeLeft({
                days: Math.floor(duration.asDays()),
                hours: duration.hours(),
                minutes: duration.minutes(),
                seconds: duration.seconds()
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [personal_sale]);

    const handleContinuePremium = () => {
        history.push('/dashboard/premium');
    };

    return (
        <div className="relative overflow-hidden bg-[#1a0f0f] p-6 sm:p-12 rounded-2xl my-4 text-white">
            {/* Dark gradient overlay */}
            <div className="absolute inset-0 bg-gradient-to-r from-[#1a0f0f] via-[#381515] to-[#1a0f0f]" />

            {/* Enhanced Red/Orange Sale Accent Glows */}
            <div className="absolute -bottom-32 right-0 w-2/3 h-[150%] bg-gradient-to-tl from-[#e54646]/30 via-[#ed3c3c]/20 to-transparent blur-3xl" />
            <div className="absolute top-0 right-0 w-1/2 h-full bg-gradient-to-bl from-[#ed3c3c]/25 via-[#e54646]/15 to-transparent blur-2xl" />

            {/* Additional subtle sale accent */}
            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-[#e54646]/[0.05] to-[#ed3c3c]/[0.08]" />

            <div className="flex flex-col lg:flex-row items-center justify-between relative z-10 gap-8 lg:gap-0">
                {/* Content */}
                <div className="relative z-10 w-full lg:max-w-xl">
                    <h1 className="text-3xl sm:text-4xl font-bold mb-3">
                        {personal_sale ? (
                            <>
                                Exclusive Post-Trial Offer:{' '}
                                <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#ff4e4e] to-[#ff7676]">
                                    40% OFF
                                </span>
                            </>
                        ) : (
                            <>
                                Premium Features Expired:{' '}
                                <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#ff4e4e] to-[#ff7676]">
                                    Upgrade Now
                                </span>
                            </>
                        )}
                    </h1>

                    {/* Countdown Timer */}
                    <div className="flex gap-4 mb-5">
                        <div className="bg-[#2a1515] px-4 py-2 rounded-lg text-center min-w-[80px]">
                            <div className="text-2xl font-bold text-[#ff4e4e]">
                                {personal_sale ? timeLeft.days : ''}
                            </div>
                            <div className="text-xs text-gray-400">Days</div>
                        </div>
                        <div className="bg-[#2a1515] px-4 py-2 rounded-lg text-center min-w-[80px]">
                            <div className="text-2xl font-bold text-[#ff4e4e]">
                                {personal_sale ? timeLeft.hours : ''}
                            </div>
                            <div className="text-xs text-gray-400">Hours</div>
                        </div>
                        <div className="bg-[#2a1515] px-4 py-2 rounded-lg text-center min-w-[80px]">
                            <div className="text-2xl font-bold text-[#ff4e4e]">
                                {personal_sale ? timeLeft.minutes : ''}
                            </div>
                            <div className="text-xs text-gray-400">Minutes</div>
                        </div>
                        <div className="bg-[#2a1515] px-4 py-2 rounded-lg text-center min-w-[80px]">
                            <div className="text-2xl font-bold text-[#ff4e4e]">
                                {personal_sale ? timeLeft.seconds : ''}
                            </div>
                            <div className="text-xs text-gray-400">Seconds</div>
                        </div>
                    </div>

                    <p className="text-base sm:text-lg leading-relaxed text-gray-300 mb-5 lg:pr-12">
                        {personal_sale ? (
                            <>
                                We hope you enjoyed your free trial! To help you continue enjoying premium features,
                                we're offering you an exclusive <span className="font-bold text-[#ff4e4e]">40% discount</span> on
                                any premium subscription. Don't let your bot lose its premium capabilities - upgrade now
                                at our best personal offer.
                            </>
                        ) : (
                            <>
                                Your premium features have expired. Upgrade now to continue using all premium features
                                and unlock the full potential of your bot.
                            </>
                        )}
                    </p>

                    <button
                        onClick={handleContinuePremium}
                        className="relative w-full sm:w-auto px-8 py-3 rounded-lg font-semibold overflow-hidden
                            transition-all duration-200 hover:-translate-y-0.5 active:translate-y-0 
                            group cursor-pointer bg-[#ff4e4e]"
                    >
                        {/* Gradient background */}
                        <div className="absolute inset-0 bg-gradient-to-r from-[#ff4e4e] via-[#ff6b6b] to-[#ff7676] 
                            group-hover:opacity-90 transition-opacity" />

                        {/* Shine effect */}
                        <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/10 to-transparent 
                            translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-1000" />

                        {/* Button shadow */}
                        <div className="absolute inset-0 shadow-xl shadow-[#ff4e4e]/20" />

                        {/* Button text */}
                        <span className="relative z-10">
                            {personal_sale ? 'Continue Premium Access' : 'Upgrade Now'}
                        </span>
                    </button>
                </div>

                {/* Promo Image - Hidden on mobile */}
                <div className="hidden lg:block relative z-10 w-[150px] mr-16">
                    <div className="animate-bounce">
                        <img
                            src={process.env.PUBLIC_URL + "/images/logo-red.png"}
                            alt="Sale Offer"
                            className="w-full h-auto transform hover:scale-105 transition-transform duration-500"
                        />
                    </div>
                </div>
            </div>

            {/* Floating stars decoration */}
            <div className="absolute top-0 left-0 w-full h-full pointer-events-none z-20">
                <div className="absolute top-2 right-[5%] text-white/30 text-3xl animate-pulse delay-100">✦</div>
                <div className="absolute top-16 right-[15%] text-white/40 text-2xl animate-pulse delay-300">✧</div>
                <div className="absolute top-4 right-[25%] text-white/30 text-3xl animate-pulse delay-500">✦</div>
                <div className="absolute top-20 right-[35%] text-white/40 text-2xl animate-pulse delay-700">✧</div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    personal_sale: state.data.user?.personal_sale
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalSaleBanner);
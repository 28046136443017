import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Helmet } from "react-helmet";

import HeaderMenu from "./HeaderMenu";
import SideMenu from "./SideMenu";
import Modules from "./Modules";
import history from "../../history";
import ReactGA from "react-ga";
import PremiumModal from "./elements/PremiumModal";

import queryString from "query-string";
import { Steps, Hints } from "intro.js-react";
import "intro.js/introjs.css";
import toast, { Toaster } from "react-hot-toast";

// Modules
import MobileMenu from "./MobileMenu";
import Moderation from "./modules/Moderation";
import Announcements from "./modules/Announcements";
import CustomCommands from "./modules/CustomCommands";
import MemeCreator from "./modules/MemeCreator";
import Fortnite from "./modules/Fortnite";
import ApexLegends from "./modules/ApexLegends";
import Settings from "./modules/Settings";
import Translator from "./modules/Translator";
import Weather from "./modules/Weather";
import Search from "./modules/Search";
import Trivia from "./modules/Trivia";
import AutoMod from "./modules/AutoMod";
import AutoRole from "./modules/AutoRole";
import Twitch from "./modules/Twitch";
import YouTube from "./modules/YouTube";
import Twitter from "./modules/Twitter";
import Reddit from "./modules/Reddit";
import Music from "./modules/Music";
import Usage from "./modules/Usage";
import TimedMessages from "./modules/TimedMessages";
import ReactionRoles from "./modules/ReactionRoles";
import Economy from "./modules/Economy";
import Levels from "./modules/Levels";
import CoCreate from "./modules/CoCreate";
import Botlist from "./modules/Botlist";
import { setServerIntents, setAuth, updateModals, getData, fetchBotData, getBots, updateErrorLogs, updateServerPopup } from "../../actions";
import Loader from "../Loader";
import Premium from "./modules/Premium";
import SaveRequired from "./elements/SaveRequired";
import PremiumBanner from "./elements/PremiumBanner";
import { ErrorBoundary } from "react-error-boundary";
// import MobileMenu from './elements/MobileMenu';
import { BetaBanner } from "./elements/BetaBanner";
import IntentCheck from "./elements/IntentCheck";
import PremiumTimer from "./elements/PremiumTimer";
import server from "../../api/server";
import ServerModal from "./elements/ServerModal";
import GettingStarted from "./elements/GettingStarted";
import Cookies from "universal-cookie";
import OfflineModal from "./elements/OfflineModal";
import { MailingListModal } from "./elements/MailingListModal";
import TextStep from "./elements/TextStep";
import SaleBanner from "./SaleBanner";
import SaleModal from "./elements/SaleModal";
import BotPanelBanner from "./BotPanelBanner";
import BotInactiveModal from "./elements/BotInactiveModal";
import ServerLimitReachedModal from "./elements/ServerLimitReachedModal";

const steps = [
	{
		element: "#invite-side-menu",
		intro: <TextStep title="Invite your Bot" content="Click the Invite button to invite your bot to your server." />,
		position: "right",
		tooltipClass: "intro-step-container",
		highlightClass: "myHighlightClass"
	},
	{
		element: "#header-bot-switcher",
		intro: <TextStep title="Change Bots" content="Open up the Bot Switcher to change and create new bots." />,
		position: "right",
		tooltipClass: "intro-step-container",
		highlightClass: "myHighlightClass"
	},
	{
		element: "#bot-status",
		intro: <TextStep title="Bot Status" content="Open up the Bot Switcher to change and create new bots." />,
		position: "right",
		tooltipClass: "intro-step-container",
		highlightClass: "myHighlightClass"
	},
	{
		element: ".module-item",
		intro: <TextStep title="Configure a Module" content="Click on a module to go to that modules page. From there you can enable and configure the module." />,
		position: "right",
		tooltipClass: "intro-step-container",
		highlightClass: "myHighlightClass"
	},
	{
		element: ".call-to-action",
		intro: <TextStep title="Upgrade to Premium" content="Upgrade your bot to premium to unlock all features." />,
		position: "right",
		tooltipClass: "intro-step-container",
		highlightClass: "myHighlightClass"
	}
];

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			premiumModal: false,
			serverModal: false,
			shown: false,
			showHelper: false,
			offlineModal: true,
			showMailingList: false,
			interval: null,
			mailingListModal: false,
			stepsEnabled: true,
			mobileNav: false,
			saleModal: false
		};
	}

	componentDidMount() {
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
		var params = null;
		if (this.props.location != undefined && this.props.location.search != "") {
			params = queryString.parse(this.props.location.search);
		}
		if (this.props.auth == false) {
			this.props.setAuth(params);
		}
		if (this.props.auth == true && this.props.loading == true) {
			this.props.getData();
			this.props.fetchBotData();
			// console.log("Fetch Bot Data 1");
		}

		const cookies = new Cookies();
		// console.lo
		if (this.props.user && this.props.user.modals && !this.props.user.modals.getting_started) {
			cookies.set("getting_started", "true");
			this.setState({ showHelper: true });
		}

		if (cookies.get("premium_version") == null) {
			var random = Math.floor(Math.random() * 2);
			if (random == 0) {
				cookies.set("premium_version", "A");
			} else {
				cookies.set("premium_version", "B");
			}
		}

		if (cookies.get("joined_server") == null) {
			this.setState({ serverModal: true });
		}

		if (cookies.get("mailingList") == null) {
			this.setState({ mailingListModal: true });
		}

		if (this.props.current_sale?.banner_url && cookies.get(this.props.current_sale?.code) == null) {
			this.setState({ saleModal: true });
		}

		this.refreshLogs();
	}

	mobileNav = (data) => {
		if (data === "open") {
			this.setState({ mobileNav: true });
			document.body.style.overflowY = "hidden";
		} else {
			const nav = document.getElementById("mobile-nav");
			nav.classList.remove("animate__slideInLeft");
			nav.classList.add("animate__slideOutLeft");
			setTimeout(() => {
				this.setState({ mobileNav: false });
				document.body.style.overflowY = "visible";
			}, 500);
		}
	};

	refreshLogs = () => {
		const interval = setInterval(async () => {
			try {
				const response = await server.post("/dashboard/errorlogs", { id: this.props.bot.id });
				if (response && response.data != false) {
					this.props.updateErrorLogs(response.data.logs);
					if (response.data.server_members_intent != this.props.intent) {
						this.props.setServerIntents(response.data.server_members_intent);
					}
				}
			} catch (e) { }
		}, 10000);
		this.setState({ interval: interval });
	};

	componentWillUnmount() {
		var interval = this.state.interval;
		if (interval != null) {
			clearInterval(interval);
			this.setState({ interval: null });
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.auth == true && this.props.loading == true) {
			this.props.getData();
			// this.props.fetchBotData()
			// console.log("Fetch Bot Data 2");
		}

		if (this.props.user && this.props.user.modals && !this.props.user.modals.getting_started && this.state.showHelper == false) {
			this.setState({ showHelper: true });
		}

		// if(this.props.auth == true && this.props.data.bot_data.length == 0){
		//     this.props.fetchBotData()
		//     console.log("Fetch Bot Data 3")
		// }

		// if(this.props.auth == true && this.props.data.bots.length == 0){
		//     this.props.getBots()
		//     console.log("Get Bots 1")
		// }

		if (this.props.page != prevProps.page) {
			window.scrollTo(0, 0);
		}
	}

	renderContent = () => {
		if (this.props.page == "modules") {
			return <Modules></Modules>;
		} else if (this.props.page == "settings") {
			return <Settings></Settings>;
		} else if (this.props.page == "moderation") {
			return <Moderation></Moderation>;
		} else if (this.props.page == "announcements") {
			return <Announcements></Announcements>;
		} else if (this.props.page == "custom commands") {
			return <CustomCommands></CustomCommands>;
		} else if (this.props.page == "meme creator") {
			return <MemeCreator></MemeCreator>;
		} else if (this.props.page == "fortnite") {
			return <Fortnite></Fortnite>;
		} else if (this.props.page == "apex legends") {
			return <ApexLegends></ApexLegends>;
		} else if (this.props.page == "translator") {
			return <Translator></Translator>;
		} else if (this.props.page == "weather") {
			return <Weather></Weather>;
		} else if (this.props.page == "search") {
			return <Search></Search>;
		} else if (this.props.page == "trivia") {
			return <Trivia></Trivia>;
		} else if (this.props.page == "automod") {
			return <AutoMod></AutoMod>;
		} else if (this.props.page == "auto-role") {
			return <AutoRole></AutoRole>;
		} else if (this.props.page == "twitch") {
			return <Twitch></Twitch>;
		} else if (this.props.page == "youtube") {
			return <YouTube></YouTube>;
		} else if (this.props.page == "twitter") {
			return <Twitter></Twitter>;
		} else if (this.props.page == "reddit") {
			return <Reddit></Reddit>;
		} else if (this.props.page == "music") {
			return <Music></Music>;
		} else if (this.props.page == "usage") {
			return <Usage></Usage>;
		} else if (this.props.page == "timed messages") {
			return <TimedMessages></TimedMessages>;
		} else if (this.props.page == "reaction roles") {
			return <ReactionRoles></ReactionRoles>;
		} else if (this.props.page == "economy") {
			return <Economy></Economy>;
		} else if (this.props.page == "levels") {
			return <Levels></Levels>;
		} else if (this.props.page == "cocreate") {
			return <CoCreate></CoCreate>;
		} else if (this.props.page == "botlist") {
			return <Botlist></Botlist>;
		} else if (this.props.page == "premium") {
			return <Premium></Premium>;
		}
	};

	renderAnalytics = () => {
		ReactGA.initialize("UA-122665050-1");
		ReactGA.pageview(window.location.pathname);
	};

	closeServerModal = async (decline) => {
		// console.log(decline);
		if (decline == true) {
			const response = server.get("/dashboard/declineserver");
		}
		this.props.updateServerPopup();
		this.setState({ serverModal: false });
	};

	renderModals = () => {
		if (this.props?.bot?.bot_inactive && this.props.bot?.online == false) {
			return (<BotInactiveModal />);
		} else if (this.props.bot?.server_limit_error) {
			return (<ServerLimitReachedModal />);
		}
		if (this.state.saleModal == 'test') {
			return (
				<SaleModal
					closeModal={(decline) => {
						const cookies = new Cookies();
						var expiresTime = new Date();
						// Add 7 days to the current date
						expiresTime.setDate(expiresTime.getDate() + 7);
						cookies.set(this.props.current_sale.code, true, {
							expires: expiresTime
						});
						this.setState({
							saleModal: false
						});
					}}
				/>
			);
		} else

			if (this.state.serverModal == true && this.props.user && this.props.user.joined_server == false) {
				return (
					<ServerModal
						closeModal={(decline) => {
							const cookies = new Cookies();
							cookies.set("joined_server", true);
							this.setState({ serverModal: false });
						}}
					/>
				);
			} else
			
			
			if (this.state.offlineModal == true && this.props.bot.online == false && this.props.intent == true) {
				return (
					<OfflineModal
						closeModal={(decline) => {
							this.setState({ offlineModal: false });
						}}
					/>
				);
			} else if (this.state.mailingListModal == true && this.props.user && this.props.user.mail_list == false) {
				return (
					<MailingListModal
						closeModal={() => {
							const cookies = new Cookies();
							cookies.set("mailingList", true);
							this.setState({ mailingListModal: false });
						}}
					/>
				);
			}
	};

	render() {
		return (
			<>
				<Helmet>
					<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				</Helmet>
				<div onError={this.errorHandler}>
					{this.renderAnalytics()}
					{this.props.auth == true && this.props.loading == false && this.props.data.user != undefined && this.props.data.bot != undefined && this.props.processing == false ? (
						<div>
							{/* {this.props.user.server_popup == false && this.state.serverModal == true ? <ServerModal closeModal={(decline) =>{this.closeServerModal(decline)}}/>:null} */}
							{/* {this.state.offlineModal == true && this.props.bot.online == false && this.props.intent == true ? <OfflineModal closeModal={(decline) =>{this.setState({offlineModal:false})}}/>:null} */}
							{/* <PremiumBanner></PremiumBanner> */}
							{/* <SaleBanner /> */}
							<MobileMenu
								update={() => {
									this.forceUpdate();
								}}
								mobileNav={this.state.mobileNav}
								mobileAction={this.mobileNav}
							/>
							{this.renderModals()}
							{/* {this.props.premium == false ? <PremiumTimer/>:null} */}
							<SideMenu mobileNav={this.state.mobileNav} mobileAction={this.mobileNav} />
							<HeaderMenu mobileNav={this.state.mobileNav} mobileAction={this.mobileNav} />
							{/* <Toaster position="top-right" /> */}

							{this.state.showHelper == true ? (
								<GettingStarted
									closeModal={(e) => {
										this.setState({ showHelper: false });
										var modals = { ...this.props.user.modals };
										modals.getting_started = true;
										this.props.updateModals(modals);
									}}
								/>
							) : null}

							{/* <Steps
                                enabled={this.state.stepsEnabled}
                                steps={steps}
                                onChange={(nextStep,nextEle) =>{
                                    if(nextStep == 2){
                                        history.push("/dashboard/settings")
                                    }
                                }}
                                initialStep={0}
                                showStepNumbers={true}
                                onExit={() =>{
                                    this.setState({ stepsEnabled: false });
                                }}
                            /> */}
							<main className="dashboard-container container-fluid">
								<IntentCheck />
								{/* {this.renderContent()} */}
								<div>{this.props.loading == false ? this.props.children : null}</div>
							</main>
						</div>
					) : (
						<Loader></Loader>
					)}
					<div>{this.props.save_required == true ? <SaveRequired></SaveRequired> : null}</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return { current_sale: state.data.current_sale, intent: state.data.server_members_intent, user: state.data.user, premium: state.data.premium, page: state.page, auth: state.auth, data: state.data, loading: state.data.loading, save_required: state.data.save_required, processing: state.data.processing, bot: state.data.bot };
};

export default connect(mapStateToProps, { setServerIntents, updateModals, updateServerPopup, setAuth, getData, fetchBotData, getBots, updateErrorLogs })(Dashboard);
